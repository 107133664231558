import React, { FC } from "react";
import dynamic from "next/dynamic";
import clsx from "clsx";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { GenericComponentTypes } from "@/types/common/library";

export enum ICON_SIZES {
  XXS = "2xs",
  XS = "xs",
  SM = "sm",
  BASE = "",
  LG = "lg",
  XL = "xl",
  XXL = "2xl",
}

export enum ICON_ROTATE {
  BASE = "",
  DEG_90 = "rotate-90",
  DEG_180 = "rotate-180",
  DEG_270 = "rotate-270",
}

export enum ICON_FLIP {
  BASE = "",
  HORIZONTAL = "flip-horizontal",
  VERTICAL = "flip-vertical",
  BOTH = "flip-both",
}

export enum ICON_ANIMATE {
  BASE = "",
  BEAT = "beat",
  FADE = "fade",
  BEAT_FADE = "beat-fade",
  FADE_BEAT = "beat-fade",
  BOUNCE = "bounce",
  FLIP = "flip",
  SHAKE = "shake",
  SPIN = "spin",
}

export enum ICON_TYPE {
  CUSTOM = "fa-kit",
  LIGHT = "fa-light",
  REGULAR = "fa-regular",
  SOLID = "fa-solid",
  DUOTONE = "fa-duotone",
  BRAND = "fa-brands",
}

export type IconProps = {
  icon: `${IconName}` | `lula-${string}` | `custom-${string}`;
  type?: ICON_TYPE;
  title?: string;
  size?: ICON_SIZES | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  rotate?: ICON_ROTATE | number;
  flip?: ICON_FLIP;
  animate?: ICON_ANIMATE;
} & GenericComponentTypes;

export const Icon: FC<IconProps> = ({
  icon,
  type,
  className,
  size,
  rotate,
  flip,
  animate,
  ...other
}) => {
  let style = {};

  let typeClass = !type ? ICON_TYPE.LIGHT : type;
  let sizeClass = !size ? "" : size;
  let rotateClass = !rotate ? "" : rotate;

  if (icon.startsWith("custom") || icon.startsWith("lula")) {
    typeClass = ICON_TYPE.CUSTOM;
  }

  if (Number.isInteger(sizeClass)) {
    sizeClass = sizeClass + "x";
  }

  if (typeof rotate == "number") {
    rotateClass = "rotate-by";
    style = {
      ...style,
      ["--fa-rotate-angle"]: `${rotate}deg`,
    };
  }

  return (
    <i
      aria-hidden="true"
      className={clsx(
        typeClass,
        `fa-${icon}`,
        {
          [`fa-${sizeClass}`]: !!sizeClass,
          [`fa-${rotateClass}`]: !!rotateClass,
          [`fa-${flip}`]: !!flip,
          [`fa-${animate}`]: !!animate,
        },
        className
      )}
      style={style}
      {...other}
    ></i>
  );
};

const FaIcon = dynamic
  ? dynamic(() => Promise.resolve(Icon), {
      ssr: false,
    })
  : Icon;

const DynamicIcon: FC<IconProps> = (props) => {
  return <FaIcon {...props} />;
};

export default DynamicIcon;
