"use client";
import { SIZES } from "@/enums/library";
import { default as LuxContainer } from "@/components/library/Container";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type ContainerProps = {
  children: string | JSX.Element | JSX.Element[];
  title?: string | JSX.Element | JSX.Element[];
  subTitle?: string | JSX.Element | JSX.Element[];
  helpLinkUrl?: string;
  maxSubTitleWidthClass?: string;
};

export default function Container({
  children,
  title,
  subTitle,
  helpLinkUrl,
  maxSubTitleWidthClass,
}: ContainerProps) {
  const { t: ct } = useTranslation("common");

  return (
    <LuxContainer
      size={SIZES.LG}
      className={clsx(
        "mt-4",
        "md:mt-0",
        "p-2",
        "md:!p-4",
        "md:!pl-0",
        "min-[1025px]:!p-8",
        "min-[1025px]:!pl-4"
      )}
    >
      {title && <h1 className={clsx("title")}>{title}</h1>}
      {(subTitle || helpLinkUrl) && (
        <div className={clsx("mb-4", maxSubTitleWidthClass)}>
          {subTitle}
          {helpLinkUrl && (
            <>
              {typeof subTitle === "string" && subTitle.length > 0 ? ". " : ""}
              <a
                className={clsx(
                  "underline",
                  "text-button_primary",
                  "whitespace-nowrap"
                )}
                target="_blank"
                href={helpLinkUrl}
              >
                {ct("needHelp")}
              </a>
            </>
          )}
        </div>
      )}
      {children}
    </LuxContainer>
  );
}
