import * as React from "react";
import clsx from "clsx";
import { SIZES } from "@/enums/library";

export type ContainerProps = {
  size?: Extract<SIZES, SIZES.SM | SIZES.BASE | SIZES.LG>;
  children?: React.ReactNode;
  className?: string;
};

const Container: React.FC<ContainerProps> = ({
  size = SIZES.BASE,
  children,
  className,
}) => {
  return (
    <div
      className={clsx("mx-auto", className, {
        ["max-w-3xl"]: size === SIZES.SM,
        ["max-w-5xl"]: size === SIZES.BASE,
        ["max-w-7xl"]: size === SIZES.LG,
      })}
    >
      {children}
    </div>
  );
};

export default Container;
