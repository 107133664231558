"use client";

import React from "react";
import clsx from "clsx";
import { IconName } from "@fortawesome/fontawesome-common-types";
import Link from "next/link";
import Icon, { ICON_SIZES } from "../library/Icon";
import Image from "next/image";

type ResourceItem = {
  icon: IconName;
  title: string;
  image?: string;
  description?: string;
  btnLink: string;
  btnText: string;
};

const MyComponent = () => {
  const data: ResourceItem[] = [
    {
      icon: "life-ring",
      title: "Let us help you get setup",
      description: "Sometimes it is easier with another human",
      btnLink: "https://www.lula.com",
      btnText: "Schedule call",
    },
    {
      icon: "newspaper",
      title: "Help Center Videos",
      image: "/icons/helpCenter.png",
      btnLink: "https://www.lula.com",
      btnText: "View all videos",
    },
    {
      icon: "display-chart-up",
      title: "Training and Education",
      description:
        "Webinars, videos, and step by step guides to make setup easier and more powerful",
      btnLink: "https://www.lula.com",
      btnText: "Learn with GAIL university",
    },
  ];

  return (
    <div className={clsx("grid", "grid-cols-1", "lg:grid-cols-3", "gap-4")}>
      {data.map((item, index) => (
        <div
          key={index}
          className={clsx("border-2", "border-gray-100", "rounded", "p-4")}
        >
          <div
            className={clsx(
              "p-2",
              "border-2",
              "inline-block",
              "border-gray-300",
              "mb-2",
              "rounded"
            )}
          >
            <Icon icon={item.icon} size={ICON_SIZES.XL} />
          </div>
          <h3 className={clsx("my-2", "font-bold")}>{item.title}</h3>
          {item.image && (
            <Image
              src={item.image}
              alt="Resource Marketing Image"
              className={clsx("w-full", "block", "mb-2")}
              width={320}
              height={78}
            />
          )}
          {item.description && <p className="text-sm">{item.description}</p>}
          <Link
            href={item.btnLink}
            target="_blank"
            className={clsx("text-button_primary", "text-sm")}
          >
            {item.btnText} <Icon icon="caret-right" />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default MyComponent;
