export enum SIZES {
  XS = "xs",
  SM = "sm",
  BASE = "base",
  LG = "lg",
  XL = "xl",
}

export enum INPUT_COLORS {
  GRAY = "gray",
  INFO = "info",
  ERROR = "failure",
  WARNING = "warning",
  SUCCESS = "success",
}
